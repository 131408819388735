<template>
  <section class="lg:container mx-auto my-12">
    <div
      class="w-11/12 mx-auto p-4 relative rounded-xl primary-gradient lg:h-52"
    >
      <NuxtPicture
        format="svg"
        src="/shape_1.svg"
        alt="shape-1"
        class="bg-transparent absolute animate-ping z-0"
      >
      </NuxtPicture>
      <NuxtPicture
        format="svg"
        src="/shape_2.svg"
        alt="shape-2"
        class="bg-transparent absolute right-0 bottom-0 animate-ping z-0"
      >
      </NuxtPicture>
      <NuxtPicture
        src="/shape_1.svg"
        format="svg"
        alt="shape-1-fixed"
        height="200px"
        class="bg-transparent absolute left-32 top-0 bottom-0 z-0"
      >
      </NuxtPicture>
      <NuxtPicture
        src="/shape_2.svg"
        format="svg"
        alt="shape-2-fixed"
        height="100px"
        class="bg-transparent absolute right-0 bottom-0 z-0"
      >
      </NuxtPicture>
      <div
        class="h-full lg:px-16 md:px-8 px-4 flex flex-1 lg:flex-row flex-col justify-between items-center gap-4 z-1 absolute inset-0"
      >
        <div class="lg:max-w-[70%] max-w-[90%] flex flex-col gap-y-4">
          <h4 class="text-white text-header-xl">
            {{ title }}
          </h4>
          <p class="text-white text-p-sm">
            {{ description }}
          </p>
        </div>
        <Button
          :aria-label="button"
          @click="openModal"
          variant="secondary"
          class="font-bold text-p-sm flex gap-4 uppercase cursor-pointer z-99"
        >
          {{ button }}
          <Icon icon="mdi-arrow-right" class="text-2xl"></Icon>
        </Button>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";
import { calltoActionSection } from "~/lib/constants";
defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  button: {
    type: String,
    default: "Learn More",
  },
});
import { useModal } from "vue-final-modal";
import ContactModal from "~/components/global/ContactModal.vue";

const openModal = async () => {
  const modal = useModal({
    component: ContactModal,
  });

  await modal.open();
};
</script>

<style scoped></style>
